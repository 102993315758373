<template>
	<div>
		<div class="white-bg">
			<div class="icon-wrapper">
				<Headshot />
			</div>
			<div class="text-center">
				<p class="mb-1 mt-3">{{ getUserName }}</p>
				<p>Free account member</p>
			</div>
		</div>
	</div>
</template>

<script>
	import { computed } from 'vue'
	import { useStore } from 'vuex'
	import Headshot from '../../../components/Snippets/Headshot'

	export default {
		setup() {
			const store = useStore()
			const getUserName = computed(() => store.state.user.data.name)

			return { store, getUserName, Headshot }
		},
	}
</script>

<style lang="scss">
	.white-bg {
		background: #ffffff;
		padding: 30px 0px;
		margin: 30px 0px;
		text-align: center;
		border-radius: 10px;

		.icon-wrapper img {
			border: 3px solid silver;
			border-radius: 60px;
			margin-bottom: 30px;
			max-width: 100%;
			background: $darkBlue;
		}
		img {
			height: 100px;
		}
	}
</style>
