<template>
  <div class="bg">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-3">
          <ProfileBox />
          <SideMenu />
        </div>
        <div class="col-12 col-lg-9">
          <div class="row">
            <div class="col-12">
              <div class="white-bg">
                <UsersLayout v-if="currentRouteName === 'AdminDashboard'"/>
                <TemplateLayout v-if="currentRouteName === 'AdminTemplates'"/>
                <CreateTemplateLayout v-if="currentRouteName === 'AdminCreateTemplate'"/>
                <EditTemplateLayout v-if="currentRouteName === 'AdminEditTemplate'" />
                <Feedback v-if="currentRouteName === 'AdminFeedback'" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import ProfileBox from '@/components/dashboard/SideBar/ProfileBox'
import SideMenu from '@/components/Admin/Sidebar/SideMenu'
import UsersLayout from '@/components/Admin/MainLayout/Users'
import TemplateLayout from '@/components/Admin/MainLayout/Templates'
import CreateTemplateLayout from '@/components/Admin/MainLayout/CreateTemplate'
import EditTemplateLayout from '@/components/Admin/MainLayout/EditTemplate'
import Feedback from '@/components/Admin/MainLayout/Feedback'

export default {
  setup() {
    const currentRouteName = computed(() => useRoute().name)
    const store = useStore()
    store.commit('showNav')

    return {
      currentRouteName,
      ProfileBox,
      SideMenu,
      UsersLayout,
      TemplateLayout,
      CreateTemplateLayout,
      EditTemplateLayout,
      Feedback
    }
  },
}
</script>

<style lang="scss">
.bg {
  background: #f2f2f2;
}
button.btn {
  width: 100%;
  border: 1px solid #30245d;
  margin-top: 10px;
  margin-bottom: 5px;
}
</style>
