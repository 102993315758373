<template>
	<SetMeta :API="false" Title="Edit Template - Admin" />
	<div class="wrap">
		<div class="container" v-if="loading">
			<div class="mr-lg-5 ml-lg-5 mb-4"></div>
			<h3 class="mb-5">Edit a template</h3>
			<form @submit.prevent="createTemplate" class="mt-5 w-75 mx-auto">
				<div class="row text-left">
					<div class="col-12">
						<div class="d-flex flex-column">
							<label for="templateName">Template Name:</label>
							<input
								type="text"
								name="templateName"
								id="templateName"
								placeholder="Name of the template"
								v-model="template.name"
							/>
						</div>
					</div>
					<div class="col-12">
						<div class="d-flex flex-column">
							<label for="templateType">Type of Template:</label>
							<select
								name="templateType"
								id="templateType"
								v-model="template.typeId"
							>
								<option value="" disabled>Please select a template type</option>
								<option
									v-for="item in templateTypes"
									:key="item.id"
									:value="item.id"
									>{{ item.name }}</option
								>
							</select>
						</div>
					</div>
					<div class="col-12">
						<div class="d-flex flex-column">
							<label for="templateName">Template Content:</label>
							<editor
								initialValue="<p>Initial editor content</p>"
								apiKey="b5ppdwq9ylodsp5psconn5vv74q3kfj8fyomu087znqny053"
								v-model="template.content"
								:init="{
									height: 300,
									menubar: false,
									plugins: [],
									toolbar:
										'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist outdent indent',
								}"
							>
							</editor>
						</div>
					</div>
					<div class="col">
						<button class="btn" type="submit">Update Template</button>
					</div>
					<div class="col-12">
						<div v-if="error" class="alert alert-danger" role="alert">
							<p>{{ error }}</p>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
	import SetMeta from '@/components/_Meta/SetData'
	import { ref, reactive } from 'vue'
	import Editor from '@tinymce/tinymce-vue'
	import axios from 'axios'
	import { useStore } from 'vuex'

	export default {
		setup() {
			// Conroller
			const store = useStore()

			// Data
			const currentID = window.location.href.split('?')[1]
			const error = ref('')
			const loading = ref(false)
			const templateTypes = ref(null)
			const template = reactive({
				id: 0,
				name: '',
				typeId: '',
				content: '',
			})

			// Methods
			const createTemplate = () => {
				error.value = ''
				if (template.name === '') {
					error.value = 'Please enter a template name'
				} else if (template.typeId === '' || template.typeId === 0) {
					error.value = 'Please select a template type'
				} else if (template.content === '') {
					error.value = 'Please enter template content'
				} else {
					axios({
						method: 'patch',
						url: store.state.devUrl + 'api/Admin/EditTemplate',
						headers: {
							'content-type': 'application/json',
							authorization: `Bearer ${localStorage.token}`,
						},
						data: template,
					})
						.then(() => {
							window.location.href = '/admin/templates'
						})
						.catch(err => {
							console.log(err)
						})
				}
			}

			// Created
			axios({
				method: 'get',
				url: store.state.devUrl + 'api/Admin/FetchTemplateType',
				headers: {
					'content-type': 'application/json',
					authorization: `Bearer ${localStorage.token}`,
				},
			})
				.then(response => {
					templateTypes.value = response.data

					axios({
						method: 'get',
						url: store.state.devUrl + 'api/Admin/FetchTemplate/' + currentID,
						headers: {
							'content-type': 'application/json',
							authorization: `Bearer ${localStorage.token}`,
						},
					})
						.then(response => {
							template.id = response.data.id
							template.name = response.data.name
							template.typeId = response.data.typeId
							template.content = response.data.content
							loading.value = true
						})
						.catch(err => {
							console.log(err)
						})
				})
				.catch(err => {
					console.log(err)
				})

			return {
				SetMeta,
				Editor,
				template,
				error,
				loading,
				createTemplate,
				templateTypes,
			}
		},
	}
</script>

<style lang="scss" scoped>
	.wrap {
		background: #fff;
		border-radius: 10px;
		overflow: hidden;
		.create {
			width: 200px;
			background: #30245d;
			color: #fff;
			position: absolute;
			right: 65px;
			bottom: -60px;
			z-index: 2;
			transition: linear all 0.3s;
		}
		.create:hover {
			transform: perspective(1px) scale(1.05);
		}
		input,
		select {
			outline: none;
			border-color: #d7d7d7;
			border-style: solid;
			border-radius: 5px;
			height: 40px;
			margin-bottom: 25px;
			padding: 5px 15px;
		}
		> .row:nth-child(odd) {
			background: #efefef;
		}
		.row {
			padding: 15px 0px;
		}
	}

	@media only screen and (max-width: 768px) {
		#app button.btn.create {
			position: initial;
		}
		.innerWrap > .row {
			flex-direction: column-reverse;
		}
	}
</style>
