<template>
	<SetMeta :API="false" Title="Feedback - Admin" />
	<div class="wrap">
		<div class="container">
			<h3 class="mb-5">Feedback</h3>
			<div class="mr-lg-5 ml-lg-5 mb-4">
				<div class="row ">
					<div class="col-12 col-lg-3">Full name</div>
					<div class="col-12 col-lg-4">Debtor</div>
					<div class="col-12 col-lg-2">Rating</div>
					<div class="col-12 col-lg-3"></div>
				</div>
				<div class="row align-items-center" v-for="item in feedback" :key="item.feedback">
					<div class="col-12 col-lg-3">
						<p>{{ item.fullname }}</p>
					</div>
					<div class="col-12 col-lg-4">
						<p>{{ item.debtor }}</p>
					</div>
					<div class="col-12 col-lg-2">
						<p>{{ item.rating }}</p>
					</div>
					<div class="col-12 col-lg-3">
						<button class="btn" @click="viewComment(item)">View Comment</button>
					</div>
					<div class="commentsPopup" v-if="currentComment === item.id">
						<div class="inner">
							<div class="title">
								<h3>Comments</h3>
								<div class="closePopup" @click="closeComments()">
									<p>x</p>
								</div>
							</div>
							<div class="content">
								<p>{{ item.comment }}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import SetMeta from '@/components/_Meta/SetData'
import axios from 'axios'
import { ref } from 'vue'
import { useStore } from 'vuex'

export default {
	setup() {
		const feedback = ref(null)
		const store = useStore()
		const currentComment = ref(0)

		const viewComment = (item) => {
			currentComment.value = item.id
		}
		const closeComments = () => {
			currentComment.value = 0
		}

		axios({
			method: 'get',
			url: store.state.devUrl + 'api/Admin/FetchFeedback',
			headers: {
				'content-type': 'application/json',
				authorization: `Bearer ${localStorage.token}`,
			},
		})
		.then(result => {
			feedback.value = result.data
		})
		.catch(err => {
			console.log(err)
		})

		return {
			SetMeta,
			feedback,
			currentComment,
			viewComment,
			closeComments
		}
	},
}
</script>

<style lang="scss" scoped>
.wrap {
	background: #fff;
	border-radius: 10px;
	overflow: hidden;
	> .row:nth-child(odd) {
		background: #efefef;
	}
	.row {
		padding: 15px 0px;
	}
}
.commentsPopup{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(0,0,0,0.3);
	z-index: 2;

	.inner{
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		margin: auto;
		width: 50%;
		height: fit-content;
		max-height: 50vh;
		background-color: #fff;
		border-radius: 20px;
		overflow: hidden;

		.title{
			position: relative;
			padding-top: 15px;
			margin-bottom: 30px;

			.closePopup{
				position: absolute;
				top: 15px;
				right: 15px;
				background-color: $darkBlue;
				width: 30px;
				height: 30px;
				border-radius: 50%;
				padding: 2px 0;
				cursor: pointer;

				p{
					font-size: 16px;
					font-weight: 400;
					color: #fff;
					margin: 0;
				}
			}
		}
	}
}
</style>
