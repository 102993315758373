<template>
	<SetMeta :API="false" Title="Templates - Admin" />
	<div class="wrap">
		<div class="container" v-if="loading">
			<div class="row">
				<div class="col-12">
					<a href="/admin/templates/create">
						<button class="btn w-auto create mb-4">
							Create Template<i class="ml-2 fas fa-plus"></i>
						</button>
					</a>
				</div>
				<div class="col-12">
					<h3 class="mb-2">Templates</h3>
				</div>
			</div>
			<div class="mr-lg-5 ml-lg-5 mb-4" v-if="templates.length !== 0">
				<div class="row ">
					<div class="col-12 col-lg">Name</div>
					<div class="col-12 col-lg">Type</div>
					<div class="col-12 col-lg">Last Edited</div>
					<div class="col-12 col-lg-4"></div>
				</div>
				<div
					class="row align-items-center"
					v-for="item in templates"
					:key="item.id"
				>
					<div class="col-12 col-lg">
						<p>{{ item.name }}</p>
					</div>
					<div class="col-12 col-lg">
						<p>{{ item.type }}</p>
					</div>
					<div class="col-12 col-lg">
						<p>{{ item.lastEdited }}</p>
					</div>
					<div class="col-12 col-lg-4">
						<a :href="item.templateFileUrl">
							<button class="btn w-auto mr-2">View</button>
						</a>
						<a :href="'/admin/templates/edit?' + item.id">
							<button class="btn w-auto mr-2">Edit</button>
						</a>
						<button class="btn w-auto" @click="deleteTemplate(item)">
							Delete
						</button>
					</div>
				</div>
			</div>
			<div class="mr-lg-5 ml-lg-5 mb-4" v-if="templates.length === 0">
				<div class="row ">
					<div class="col-12 col-lg">There are currently no templates</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import SetMeta from '@/components/_Meta/SetData'
	import axios from 'axios'
	import { ref } from 'vue'
	import { useStore } from 'vuex'

	export default {
		setup() {
			const templates = ref(null)
			const store = useStore()
			const loading = ref(false)

			// Methods
			const deleteTemplate = item => {
				const Body = {
					id: item.id,
				}
				axios({
					method: 'delete',
					url: store.state.devUrl + 'api/Admin/RemoveTemplate',
					headers: {
						'content-type': 'application/json',
						authorization: `Bearer ${localStorage.token}`,
					},
					data: Body,
				})
					.then(() => {
						window.location.href = '/admin/templates'
					})
					.catch(err => {
						console.log(err)
					})
			}

			// Created
			axios({
				method: 'get',
				url: store.state.devUrl + 'api/Admin/FetchTemplate',
				headers: {
					'content-type': 'application/json',
					authorization: `Bearer ${localStorage.token}`,
				},
			})
				.then(result => {
					templates.value = result.data
					loading.value = true
				})
				.catch(err => {
					console.log(err)
				})

			return {
				SetMeta,
				templates,
				loading,
				deleteTemplate,
			}
		},
	}
</script>

<style lang="scss" scoped>
	.wrap {
		background: #fff;
		border-radius: 10px;
		overflow: hidden;
		.create {
			width: 200px;
			background: #30245d;
			color: #fff;
			position: absolute;
			right: 65px;
			bottom: -60px;
			z-index: 2;
			transition: linear all 0.3s;
		}
		.create:hover {
			transform: perspective(1px) scale(1.05);
		}
		input {
			outline: none;
			border-color: #d7d7d7;
			border-style: solid;
			border-radius: 5px;
			height: 40px;
			margin-bottom: 25px;
			padding: 5px 15px;
		}
		> .row:nth-child(odd) {
			background: #efefef;
		}
		.row {
			padding: 15px 0px;
		}
	}

	@media only screen and (max-width: 768px) {
		#app button.btn.create {
			position: initial;
		}
		.innerWrap > .row {
			flex-direction: column-reverse;
		}
	}
</style>
