<template>
	<div>
		<div class="white-bg drop-shadox">
			<ul class="sideMenu">
				<li>
					<a href="/admin" class="row m-0 align-items-center" :class="{ active: currentRouteName === 'AdminDashboard' }">
						<i class="fas fa-users"></i>
						<p>Users</p>
					</a>
				</li>
				<li>
					<a href="/admin/feedback" class="row m-0 align-items-center" :class="{ active: currentRouteName === 'AdminFeedback' }">
						<i class="fas fa-users"></i>
						<p>Feedback</p>
					</a>
				</li>
				<li>
					<a href="/" class="row m-0 align-items-center">
						<img src="../../../assets/logos/icon.svg" alt="" />
						<p>Return to Portal</p>
					</a>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

export default {
	setup() {
		const currentRouteName = computed(() => useRoute().name)
		const store = useStore()

		return {
			currentRouteName,
			store,
		}
	},
}
</script>

<style lang="scss" scoped>
.white-bg {
	padding: 0px;
	overflow: hidden;
	box-shadow: 1px 0px 6px #5f5f5f2e;
}
</style>

<style lang="scss" scoped>
.active {
	background: #efefef;
}
.sideMenu {
	padding: 0;
	margin-bottom: 0px;
	a {
		color: $darkBlue;
		align-items: center;
	}

	p {
		margin-bottom: 0px;
	}
	.cursor:hover,
	a:hover {
		cursor: pointer;
	}
	li {
		list-style: none;
	}
	p,
	i {
		font-size: 18px;
		padding: 15px 0px;
		margin-right: 10px;
		margin-left: 30px;
	}
	img {
		width: 20px;
		height: 18px;
		max-width: 100%;
		margin-right: 10px;
		position: relative;
		margin-left: 30px;
	}
	.fa-user-shield {
	}
}
.sideMenu.active {
	background: #efe;
}
</style>
