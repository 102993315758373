<template>
	<SetMeta :API="false" Title="Users - Admin" />
	<div class="wrap">
		<div class="container">
			<h3 class="mb-5">Users</h3>
			<div class="mr-lg-5 ml-lg-5 mb-4">
				<div class="row ">
					<div class="col-12 col-lg-3">Full name</div>
					<div class="col-12 col-lg-3">Email Address</div>
					<div class="col-12 col-lg-3">Company name</div>
					<div class="col-12 col-lg-3">Subscription</div>
				</div>
				<div class="row align-items-center" v-for="item in users" :key="item.id">
					<div class="col-12 col-lg-3">
						<p>{{ item.fullName }}</p>
					</div>
					<div class="col-12 col-lg-3">
						<p>{{ item.email }}</p>
					</div>
					<div class="col-12 col-lg-3">
						<p>{{ item.company }}</p>
					</div>
					<div class="col-12 col-lg-3">
						<p>{{ item.subscription }}</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import SetMeta from '@/components/_Meta/SetData'
	import axios from 'axios'
	import { ref } from 'vue'
	import { useStore } from 'vuex'

	export default {
		setup() {
			const users = ref(null)
			const store = useStore()

			axios({
				method: 'get',
				url: store.state.devUrl + 'api/Admin/FetchUsers',
				headers: {
					'content-type': 'application/json',
					authorization: `Bearer ${localStorage.token}`,
				},
			})
				.then(result => {
					users.value = result.data
				})
				.catch(err => {
					console.log(err)
				})

			return {
				SetMeta,
				users,
			}
		},
	}
</script>

<style lang="scss" scoped>
	.wrap {
		background: #fff;
		border-radius: 10px;
		overflow: hidden;
		> .row:nth-child(odd) {
			background: #efefef;
		}
		.row {
			padding: 15px 0px;
		}
	}
</style>
