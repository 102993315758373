<template>
  <div class="Api" v-if="$props.API">
    <!-- Coming Soon (Need Axois for VUE 3) -->
  </div>
  <div class="NoApi" v-if="!$props.API">
    <div class="PropDescription" v-if="$props.Description">
      <div class="PropsPageImage" v-if="$props.CurrentPageImage">
        <teleport to="head">
          <title>{{ $props.Title }} - {{ meta.siteName}}</title>
          <meta name="description" :content="$props.Description">
          <meta name="keywords" :content="meta.keywords">

          <meta property="og:title" :content="$props.Title + ' - ' + meta.siteName">
          <meta property="og:site_name" :content="meta.siteName">
          <meta property="og:description" :content="$props.Description">
          <meta property="og:image" :content="$props.CurrentPageImage">
          <meta property="og:url" :content="meta.url">
          <meta property="og:type" content="website">
        </teleport>
        <div class="Twitter" v-if="meta.twitter">
          <teleport to="head">
            <meta name="twitter:title" :content="$props.Title + ' - ' + meta.siteName">
            <meta name="twitter:description" :content="$props.Description">
            <meta name="twitter:image" :content="$props.CurrentPageImage">
            <meta name="twitter:site" :content="meta.url">
            <meta name="twitter:creator" :content="meta.twitterAt">
            <meta name="twitter:card" content="summary">
          </teleport>
        </div>
      </div>
      <div class="PropsPageImage" v-if="!$props.CurrentPageImage">
        <teleport to="head">
          <title>{{ $props.Title }} - {{ meta.siteName}}</title>
          <meta name="description" :content="$props.Description">
          <meta name="keywords" :content="meta.keywords">

          <meta property="og:title" :content="$props.Title + ' - ' + meta.siteName">
          <meta property="og:site_name" :content="meta.siteName">
          <meta property="og:description" :content="$props.Description">
          <meta property="og:image" :content="meta.pageImage">
          <meta property="og:url" :content="meta.url">
          <meta property="og:type" content="website">
        </teleport>
        <div class="Twitter" v-if="meta.twitter">
          <teleport to="head">
            <meta name="twitter:title" :content="$props.Title + ' - ' + meta.siteName">
            <meta name="twitter:description" :content="$props.Description">
            <meta name="twitter:image" :content="meta.pageImage">
            <meta name="twitter:site" :content="meta.url">
            <meta name="twitter:creator" :content="meta.twitterAt">
            <meta name="twitter:card" content="summary">
          </teleport>
        </div>
      </div>
    </div>
    <div class="PropDescription" v-if="!$props.Description">
      <div class="PropsPageImage" v-if="$props.CurrentPageImage">
        <teleport to="head">
          <title>{{ $props.Title }} - {{ meta.siteName}}</title>
          <meta name="description" :content="meta.description">
          <meta name="keywords" :content="meta.keywords">

          <meta property="og:title" :content="$props.Title + ' - ' + meta.siteName">
          <meta property="og:site_name" :content="meta.siteName">
          <meta property="og:description" :content="meta.description">
          <meta property="og:image" :content="$props.CurrentPageImage">
          <meta property="og:url" :content="meta.url">
          <meta property="og:type" content="website">
        </teleport>
        <div class="Twitter" v-if="meta.twitter">
          <teleport to="head">
            <meta name="twitter:title" :content="$props.Title + ' - ' + meta.siteName">
            <meta name="twitter:description" :content="meta.description">
            <meta name="twitter:image" :content="$props.CurrentPageImage">
            <meta name="twitter:site" :content="meta.url">
            <meta name="twitter:creator" :content="meta.twitterAt">
            <meta name="twitter:card" content="summary">
          </teleport>
        </div>
      </div>
      <div class="PropsPageImage" v-if="!$props.CurrentPageImage">
        <teleport to="head">
          <title>{{ $props.Title }} - {{ meta.siteName}}</title>
          <meta name="description" :content="meta.description">
          <meta name="keywords" :content="meta.keywords">

          <meta property="og:title" :content="$props.Title + ' - ' + meta.siteName">
          <meta property="og:site_name" :content="meta.siteName">
          <meta property="og:description" :content="meta.description">
          <meta property="og:image" :content="meta.pageImage">
          <meta property="og:url" :content="meta.url">
          <meta property="og:type" content="website">
        </teleport>
        <div class="Twitter" v-if="meta.twitter">
          <teleport to="head">
            <meta name="twitter:title" :content="$props.Title + ' - ' + meta.siteName">
            <meta name="twitter:description" :content="meta.description">
            <meta name="twitter:image" :content="meta.pageImage">
            <meta name="twitter:site" :content="meta.url">
            <meta name="twitter:creator" :content="meta.twitterAt">
            <meta name="twitter:card" content="summary">
          </teleport>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    API: Boolean,
    Title: String,
    Description: String,
    CurrentPageImage: String
  },

  setup() {
    const meta = {
      siteName: "Detbshelf",
      description : "Debtshelf Portal",
      keywords: "Detbshelf",
      url: window.location.href,
      twitter: false,
      twitterAt: "",
      pageImage: ""
    }

    return { meta }
  }
}
</script>
